import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastConfig } from '@shared/types/toast.type';

@Component({
  selector: 'app-toast-success',
  templateUrl: './toast-success.component.html',
  styleUrl: './toast-success.component.scss'
})
export class ToastSuccessComponent {
  @Input({ required: true }) config!: ToastConfig;

  @Output() closeAction = new EventEmitter<void>();
  @Output() reloadAction = new EventEmitter<void>();

  readonly defaultSuccessConfig: Required<
    Pick<ToastConfig, 'icon' | 'title'>
  > = {
    title: 'Sucesso!',
    icon: {
      family: 'regular',
      name: 'circle-check',
    },
  };
}
