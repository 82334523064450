<div class="toast d-flex box-shadow" [class.opened]="toast.message && isOpened">
  <app-toast-danger
    *ngIf="toast.type === 'DANGER'"
    class="w-100"
    [config]="toast"
    (closeAction)="closeToast()"
    (reloadAction)="reloadPage()"
  />

  <app-toast-success
    *ngIf="toast.type === 'SUCCESS'"
    class="w-100"
    [config]="toast"
    (closeAction)="closeToast()"
    (reloadAction)="reloadPage()"
  />
</div>
