import { Component, OnInit } from '@angular/core';
import { JwtService } from '@services/jwt.service';
import { LocalStorageService } from '@services/local-storage.service';
import { UserManagerService } from '@services/user-manager.service';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import { JwtDecoded } from '@shared/types/jwt.type';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _jwtService: JwtService,
    private readonly _localstorageService: LocalStorageService,
    private readonly _primengConfig: PrimeNGConfig,
    private readonly _userManagerService: UserManagerService,
  ) {}

  ngOnInit(): void {
    this._loadUserData();

    this._primengConfig.setTranslation({
      clear: 'Limpar',
      apply: 'Aplicar',
  });
  }

  private _loadUserData() {
    const authToken = this._localstorageService.get(
      LocalStorageKeysEnum.ACCESS_TOKEN,
    );
    const jwtDecoded = this._jwtService.decode(authToken) as JwtDecoded;

    if (!jwtDecoded) return;

    this._userManagerService.setUser(jwtDecoded.user);
  }
}
