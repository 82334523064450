import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { LocalStorageService } from '@services/local-storage.service';
import { UserManagerService } from '@services/user-manager.service';
import { LocalStorageKeysEnum } from '@shared/enums/local-storage.enum';
import { catchError, throwError } from 'rxjs';

export const AuthTokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const localStorageService = inject(LocalStorageService);
  const userManagerService = inject(UserManagerService);
  const router = inject(Router);
  const authToken = localStorageService.get(LocalStorageKeysEnum.ACCESS_TOKEN);
  const isApi = req.url.includes(environment.apiUrl);
  const pathsWithoutAuthToken = ['login'];

  let transformedReq: HttpRequest<unknown> = req;

  if (
    isApi &&
    !!authToken &&
    mustHaveAuthToken(req.url, pathsWithoutAuthToken)
  ) {
    transformedReq = req.clone({
      setHeaders: {
        authorization: `Bearer ${authToken}`,
      },
    });
  }

  return next(transformedReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 403) {
        userManagerService.setUser(null);
        localStorageService.clear();
        router.navigate(['/login']);
      }
      return throwError(() => error);
    })
  );;
};

function mustHaveAuthToken(path: string, pathsWithoutAuthToken: string[]) {
  for (const pathWithoutAuthToken of pathsWithoutAuthToken) {
    if (path.includes(pathWithoutAuthToken)) return false;
  }

  return true;
}

