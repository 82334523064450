import { NgModule } from '@angular/core';
import { NgIf } from '@angular/common';
import { ToastSuccessComponent } from './toast-success.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [ToastSuccessComponent],
  imports: [IconModule, NgIf],
  exports: [ToastSuccessComponent],
})
export class ToastSuccessModule {}
