import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastConfig } from '@shared/types/toast.type';

@Component({
  selector: 'app-toast-danger',
  templateUrl: './toast-danger.component.html',
  styleUrl: './toast-danger.component.scss',
})
export class ToastDangerComponent {
  @Input({ required: true }) config!: ToastConfig;

  @Output() closeAction = new EventEmitter<void>();
  @Output() reloadAction = new EventEmitter<void>();

  readonly defaultErrorConfig: Required<
    Pick<ToastConfig, 'icon' | 'title' | 'message'>
  > = {
    title: 'Algo deu errado',
    message: 'Por favor, tente novamente mais tarde.',
    icon: {
      family: 'regular',
      name: 'circle-xmark',
    },
  };
}
