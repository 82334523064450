import { NgModule } from '@angular/core';
import { NgIf } from '@angular/common';
import { ToastComponent } from './toast.component';
import { IconModule } from '../icon/icon.module';
import { ToastDangerModule } from '../toast-danger/toast-danger.module';
import { ToastSuccessModule } from '../toast-success/toast-success.module';

@NgModule({
  declarations: [ToastComponent],
  imports: [IconModule, NgIf, ToastDangerModule, ToastSuccessModule],
  exports: [ToastComponent],
})
export class ToastModule {}
